<template>
  <div>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-for="n in 2" :key="n"
            class="mx-auto pa-1"
            width="600"
            type="card"
          ></v-skeleton-loader>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <!-- PILIH INFLUENCER -->
      <div v-if="itemData[0].status === 1"> 
        <p v-if="isMobile()" class="body-1 black--text font-weight-bold text-capitalize">{{ itemData[0].name }}</p>
        <p v-else class="headline black--text font-weight-bold text-capitalize">{{ itemData[0].name }}</p>
        <v-row class="mt-0 mb-5" v-for="list in itemData" :key="list.id">
          <div class="mt-0 pt-0 col-sm-12" >
            <v-card class="mt-0" color="text-center primary">
              <v-card-text class="mb-2">
                <div v-if="isMobile()" class="white--text body-1 font-weight-medium d-flex">
                  {{ (list.level === 'A') ? 'Nano Influencer' : 'Micro Influencer' }}
                  <div class="text-capitalize ml-auto">{{ (list.level === 'A') ? selectedA : selectedB }}{{ '/'+list.qty }}</div>
                </div>
                <div v-else class="white--text headline font-weight-medium d-flex">
                  {{ (list.level === 'A') ? 'Nano Influencer' : 'Micro Influencer' }}
                  <div class="text-capitalize ml-auto">{{ (list.level === 'A') ? selectedA : selectedB }}{{ '/'+list.qty }}</div>
                </div>
              </v-card-text>
              <daftar-influencer :qty="list.qty" :type="list.level" :showSelect="true" :quotation_id="list.quotation_id" @eventValue="_selectedInfluencer"></daftar-influencer>
            </v-card>
          </div>
        </v-row>
          
        <v-row justify="center">
          <v-dialog
            v-model="saveDialog"
            max-width="400"
          >
            <v-card>
              <div class="d-flex justify-center pt-15">
                <v-progress-circular
                  v-if="isLoadingSave"
                  :width="5"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <div v-else>
                  <i v-if="cartSuccess" class="far fa-check-circle primary--text" style="font-size:55px"></i>
                  <i v-else class="far fa-times-circle error--text" style="font-size:55px"></i>
                </div>
              </div>
              <v-card-title class="d-flex justify-center">
                <div v-if="!isLoadingSave">
                  <p v-if="cartSuccess" class="text-h5 primary--text mb-0">Influencer telah disimpan</p>
                  <p v-else class="text-h5 error--text mb-0">Influencer gagal disimpan</p>
                </div>
              </v-card-title>
              <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
              <v-card-actions v-if="!isLoadingSave" class="d-flex justify-center">
                <!-- <v-spacer></v-spacer> -->
                  <v-btn
                    color="primary"
                    class="text-capitalize"
                    @click="refresh"
                  ><i class="fa fa-eye mr-2"></i>
                    Lihat Detail
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-dialog
          v-model="alert"
          max-width="420"
        >
          <v-alert type="error">
            {{alertMessage}} <button style="float:right" type="button" v-on:click="closeAlert" class="close right" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </v-alert>
        </v-dialog>
        <br>
        <v-bottom-navigation
          color="primary"
          fixed
          class="d-flex"
          horizontal
          style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important"
        >
        <div v-if="isMobile()" class="ml-auto pb-0 mb-0">
          <v-btn text class="mr-0" color="primary" style="height:56px !important; color:#fff !important" v-for="list in itemData" :key="list.id">
            <span class="subtitle-2 font-weight-bold primary--text">{{ (list.level === 'A') ? 'Nano ' : 'Micro ' }} ({{ (list.level === 'A') ? selectedA : selectedB }}{{ '/'+list.qty }})</span>
          </v-btn>
          <v-btn @click="checkForm()" small color="primary" style="height:56px !important; color:#fff !important;">
            <span class="caption font-weight-bold">Simpan</span>
            <i class="fas fa-save mr-2"></i>
          </v-btn>
        </div>
        <div v-else class="ml-auto pb-0 mb-0">
          <v-btn text class="mr-10" color="primary" style="height:56px !important; color:#fff !important" v-for="list in itemData" :key="list.id">
            <span class="subtitle-2 font-weight-bold primary--text">{{ (list.level === 'A') ? 'Nano Influencer' : 'Micro Influencer' }} ({{ (list.level === 'A') ? selectedA : selectedB }}{{ '/'+list.qty }})</span>
          </v-btn>
          <v-btn @click="checkForm()" medium class="mr-2" color="primary" style="color:#fff !important; border-radius:5px">
            <span class="subtitle-2 font-weight-bold">Simpan</span>
            <i class="fas fa-save mr-2"></i>
          </v-btn>
        </div>
        </v-bottom-navigation>
      </div>
      <!-- LIHAT INFLUENCER -->
      <div v-else>
        <p class="headline black--text font-weight-bold">{{ itemData[0].name }}</p>
        <v-row class="mt-0 mb-5" v-for="list in itemData" :key="list.id">
          <div class="mt-0 pt-0 col-sm-12" >
            <v-card class="mt-0" color="text-center primary">
              <v-card-text class="mb-2">
                <div class="white--text headline font-weight-medium d-flex">
                  {{ (list.level === 'A') ? list.qty +' Nano Influencer' : list.qty +' Micro Influencer' }}
                </div>
              </v-card-text>
              <daftar-influencer :qty="list.qty" :type="list.level" :showSelect="false" :quotation_id="list.quotation_id" @eventValue="_selectedInfluencer"></daftar-influencer>
            </v-card>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DaftarInfluencer from './Table.vue'

import axios from 'axios'

export default defineComponent({
  components: {
    DaftarInfluencer
  },
  setup() {
    return{
      itemId:'',
      itemData:[],
      packageId:'',
      isLoading:true,
      bpmA: 0,
      bpmB: 0,
      alert:false,
      saveDialog:false,
      isLoadingSave:true,
      cartSuccess:false,
      alertMessage:'',
      selectedA:0,
      selectedInfluencerA:[],
      selectedB:0,
      selectedInfluencerB:[]
    }
  },
  mounted(){
    this.item = this.$route.query.item
    this.itemId = this.decryptData(this.item)
    this.loadQuotation();
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadQuotation(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/'+this.itemId, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.itemData = response.data.data
            for(let a = 0; a < this.itemData.length; a++){
              if(this.itemData[a].level === 'A'){
                this.bpmA = parseInt(this.itemData[a].qty);
              } else {
                this.bpmB = parseInt(this.itemData[a].qty);
              }
            }
            // if(this.packageId === 'custom'){
            //   this.itemData.description = response.data.type
            //   this.itemData.type = response.data.level
            //   this.itemData.content = response.data.format
            // }
            // this.bpm = parseInt(this.itemData.qty);
            setTimeout(() => { 
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    goToStarted(){
      this.$router.push({name:'campaign'}); 
    },
    checkForm(){
      if(this.bpmA === this.selectedA && this.bpmB === this.selectedB){
        this.save()
        this.saveDialog = true
      } else if(this.bpmA < this.selectedA){
        console.log(this.bpmA)
        console.log(this.selectedA)
        this.alertMessage = 'Nano Influencer melebihi jumlah (' +this.selectedA+'/'+this.bpmA+')' 
        this.alert = true;
      } else if(this.bpmA > this.selectedA){
        if(this.selectedA <= 0){
          this.alertMessage = 'Nano Influencer belum dipilih (' +this.selectedA+'/'+this.bpmA+')' 
          this.alert = true;
        } else {
          this.alertMessage = 'Nano Influencer belum cukup jumlah (' +this.selectedA+'/'+this.bpmA+')' 
          this.alert = true;
        }
      } else if(this.bpmB < this.selectedB){
        this.alertMessage = 'Micro Influencer melebihi jumlah (' +this.selectedB+'/'+this.bpmB+')' 
        this.alert = true;
      } else if(this.bpmB > this.selectedB){
        if(this.selectedB <= 0){
          this.alertMessage = 'Micro Influencer belum dipilih (' +this.selectedB+'/'+this.bpmB+')' 
          this.alert = true;
        } else {
          this.alertMessage = 'Micro Influencer belum cukup jumlah (' +this.selectedB+'/'+this.bpmB+')' 
          this.alert = true;
        }
      } else {
        console.log(this.bpm)
        console.log(this.selected)
          this.alertMessage = 'Terjadi kesalahan. Silahkan coba lagi.'
          this.alert = true;
      }
    },
    save(){
      this.isLoadingSave = true;
      let token = localStorage.getItem('token')
      let finalInfluencer = this.selectedInfluencerA + this.selectedInfluencerB;
      axios.post(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/saveInfluencer', {
          quotation_id:this.itemId,
          influencer_id: finalInfluencer,
        }, {
          headers: {
          'Authorization': 'Bearer '+ token
        }
      }).then(response => {
        console.log(response)
        if(response){
          // this.itemData = response.data
          // this.bpm = this.itemData.qty;
          setTimeout(() => { 
            this.isLoadingSave = false
            this.cartSuccess = true
          }, 200);
        }
      }).catch(error => {
        console.log(error)
        setTimeout(() => { 
          this.isLoadingSave = false
          this.cartSuccess = false
        }, 200);
      });
    },
    refresh(){
      this.$router.go(0);
    },
    _selectedInfluencer(value){
      let newValue = '';
      for(let a = 0; a < value.length; a++){
        newValue += value[a].id+',';
      }
      if(value.length > 0){
        if(value[0].type === 'A'){
          this.selectedInfluencerA = newValue;
          this.selectedA = value.length;
        } else {
          this.selectedInfluencerB = newValue;
          this.selectedB = value.length;
        }
      } else {
        this.selectedInfluencerA = newValue;
        this.selectedA = value.length;
        this.selectedInfluencerB = newValue;
        this.selectedB = value.length;
      }
    },
    closeAlert(){
      this.alert = false
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
    //this.$router.push({name:'campaign-payment'}); 
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    }
  },
})
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center;
    }
</style>