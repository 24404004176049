<template>
  <div class="row">
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else class="col-md-12">
      <v-card color="transparent" class="elevation-0">
          <!-- <v-card-title class="font-weight-medium pl-0"><div>Pilih Influencer</div></v-card-title> -->
          <v-data-table
              v-model="selected"
              :headers="headers"
              :items="listInfluencers"
              item-key="id"
              :show-select="showSelect"
              :loading="loadingTable"
              loading-text="Loading... Memuat Data"
              class="elevation-1"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
              @input="enterSelect()"
          >
            <!-- <template v-slot:[`header.data-table-select`]> </template> -->
            <template v-slot:top v-if="showSelect">
                <v-container fluid class="mb-0 pb-5">
                    <v-row >
                        <!-- <v-col cols="3">
                            <v-row class="pa-6">
                                <v-select
                                    :items="followerList"
                                    v-model="followerValue"
                                    label="Level"
                                ></v-select>
                            </v-row>
                        </v-col> -->
                        <v-col cols="4" class="pb-0">
                            <v-row class="pa-6 pb-0">
                                <v-select
                                    :items="categoryList"
                                    v-model="categoryValue"
                                    label="Kategori"
                                    class="mb-0"
                                ></v-select>
                            </v-row>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <v-row class="pa-6 pb-0">
                                <v-select
                                    :items="genderList"
                                    v-model="genderValue"
                                    label="Jenis Kelamin"
                                ></v-select>
                            </v-row>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <v-row class="pa-6 pb-0">
                                <v-select
                                    :items="locationList"
                                    v-model="locationValue"
                                    label="Domisili"
                                ></v-select>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
                <!-- <v-container class="pt-0 pb-0">
                  <v-switch
                    v-if="categoryValue === null && genderValue === null && locationValue === null"
                    :input-value="false"
                    @change="selectRecomended($event)"
                    label="Pilih Otomatis (Rekomendasi VDC)"
                    class="pa-3 pt-0 pb-0"
                  ></v-switch>
                </v-container> -->
            </template>
            <!-- <template v-slot:[`item.data-table-select`]>
              <v-simple-checkbox
                v-if="!recomenSelect"
                disabled
              ></v-simple-checkbox>
            </template> -->
            <template v-slot:[`item.photo`]="{ item }">
              <!-- <v-badge
                bordered
                color="primary"
                icon="fa fa-check"
                overlap
                class="mt-3 mb-3"
              > -->
                <v-avatar class="">
                  <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
                  <v-img :src="item.photo" :alt="item.photo" height="80px"></v-img>
                </v-avatar>
              <!-- </v-badge> -->
            </template>

            <template v-slot:[`item.categories`]="{ item }">
              <div>
                <v-chip 
                  class="ma-1 caption"
                  color="secondary"
                  v-for="category in item.categories" :key="category.id"
                >
                  {{ category.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.instagram`]="{ item }">
              <div v-if="item.isPriority === 1">
                {{ item.instagram }}<br>
                <v-chip
                  color=""
                  class="primary--text mb-1"
                  label
                  small
                >
                  Recommended
                </v-chip>
              </div>
              <div v-else>
                {{ item.instagram }}
              </div>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              <div v-if="item.gender === 'L'">
                Laki-laki
              </div>
              <div v-else>
                Perempuan
              </div>
            </template>
            <template v-slot:[`item.engagement_rate`]="{ item }">
              <div>
                  {{ item.engagement_rate }} %
              </div>
            </template>
            <template v-slot:[`item.city_name`]="{ item }">
              <div class="text-capitalize">
                  {{ item.city_name }}
              </div>
            </template>
          </v-data-table>
          <div class="text-center mt-2 pt-0">
              <v-pagination
                  v-model="page"
                  :length="pageCount"
              ></v-pagination>
          </div>
          <div class="text-center pb-2">
          </div>
      </v-card>
    </div>
    
  </div>
</template>

<script>
import {
  mdiChevronRight,
  mdiCartPlus
} from '@mdi/js'
import axios from 'axios'

export default {
  props:['qty', 'type', 'showSelect', 'quotation_id'],
  setup() {
    return {
        selected: [],
        selectedCount:0,
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        selectStatus:false,
        selectLimit:2,
        listInfluencers:[],
        recomenSelect:false,
        isLoading:true,
        loadingTable:true,
        genderList: [
          {text: "Semua", value: null},
          {text: "Laki - laki", value: 'L'},
          {text: "Perempuan", value: 'P'},
        ],
        genderValue: null,
        categoryList: [],
        categoryValue: null,
        locationList: [
          {text: "Semua", value: null},
          {text: "Jabodetabek", value: '1'},
          // {text: "Luar Jabodetabek", value: '2'},
        ],
        locationValue: null,
        icons: {
            mdiChevronRight,
            mdiCartPlus
        },
        items: [
          { header: 'Harga Detail' },
          { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Harga Selektif', subtitle: `<span class="font-weight-bold">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?` },
          { divider: true, inset: true },
          { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Harga Paket', subtitle: `<span class="font-weight-bold">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.` }
        ],
        // packageId:'',
        package:''
    }
  },
  mounted() {
    // this.package = this.$route.query.package
    // this.packageId = this.decryptData(this.package)
    
    if(this.type != ''){
      if(this.showSelect){
        this.loadInfluencers()
      } else {
        this.loadViewInfluencers()
      }
      this.loadCategories()
    }
  },
  computed: {
      headers() {
        return [
          {text: 'No.', value: 'num', sortable: false, align: 'center'},
          // {text: 'VDC', value: 'isPriority', sortable: false, align: 'center'},
          {text: 'Gambar', value: 'photo', sortable: false, align: 'center'},
          {text: 'Username', value: 'instagram', sortable: false, align: 'center'},
          {text: 'Follower', align: 'left', sortable: false, value: 'follower_ig', filter: this.nameFilter},
          {text: 'Engagement Rate', value: 'engagement_rate', sortable: false, align: 'center', filter: this.followerFilter},
          {text: 'Kategori', value: 'categories', sortable: false, filter: this.categoryFilter},
          {text: 'Jenis Kelamin', value: 'gender', align: 'center', sortable: false, filter: this.genderFilter},
          {text: 'Domisili', value: 'city_name', align: 'center', sortable: false,filter: this.locationFilter},
        ]
      },
    },
  methods: {
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadInfluencers(){
      this.isLoading = true;
      this.loadingTable = true
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getInfluencers/'+this.type, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          console.log(response)
          if(response){
            // this.listInfluencers = response.data
            let data = response.data;
            let num = 1
            for(let a=0; a < data.length; a++){
              data[a].num = num++
              data[a].follower_ig = this.numberFormat(data[a].follower_ig);
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            // console.log(this.listInfluencers)
            setTimeout(() => { 
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadViewInfluencers(){
      this.isLoading = true;
      this.loadingTable = true
      let token = localStorage.getItem('token')
      console.log('OOOOO', this.quotation_id)
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/getInfluencers/'+this.type+'/'+this.quotation_id, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          console.log(response)
          if(response){
            // this.listInfluencers = response.data
            let data = response.data;
            let num = 1
            for(let a=0; a < data.length; a++){
              data[a].num = num++
              data[a].follower_ig = this.numberFormat(data[a].follower_ig);
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            // console.log(this.listInfluencers)
            setTimeout(() => { 
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadCategories(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'categories', {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            let result = [
              {text: "Semua", value: null}
            ]
            for(let a =0; a < data.length; a++){
              let list = {
                'text': data[a].name,
                'value': data[a].name
              }
              result.push(list)
            }
            this.categoryList = result
            setTimeout(() => { 
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    enterSelect() {
      // this.selectedCount = this.selected.length;
      // if(this.selected.length >= this.qty){
      //   console.log('MANTAP',this.selected)
      // }
      // this.$emit('eventCount', this.selectedCount);
      this.$emit('eventValue', this.selected);
      // console.log(this.selected.map(e => e.name));  // logs all the selected items.
    },
    selectRecomended(val){
      this.loadingTable = true
      if(val){
        for(let a=0; a < this.qty; a++){
          this.selected.push(this.listInfluencers[a])
        }
        console.log(this.selected)
        this.$emit('eventValue', this.selected);
        this.recomenSelect = true
        setTimeout(() => { 
          this.loadingTable = false
        }, 500);
      } else {
        this.selected = []
        this.$emit('eventValue', this.selected);
        this.recomenSelect = false
        setTimeout(() => { 
          this.loadingTable = false
        }, 300);
      }
    },
    categoryFilter(value) {
      if (!this.categoryValue) {
        return true;
      }
      // return value === this.categoryValue;
      for(let a=0; a < value.length; a++){
        if(value[a].name.includes(this.categoryValue)){
          return value;
        };
      }
    },
    genderFilter(value) {
      if (!this.genderValue) {
        return true;
      }
      return value === this.genderValue;
    },
    locationFilter(value) {
      if (!this.locationValue) {
        return true;
      }
      let jabodetabek = ['Jakarta', 'Bogor', 'Depok', 'Tangerang Selatan', 'Bekasi']
      if(this.locationValue === '1'){
        for(let a=0; a < jabodetabek.length; a++){
          if(value.includes(jabodetabek[a])){
            return value;
          };
        }
      } 
      
      return value === this.locationValue;
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>