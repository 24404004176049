<template>
  <v-row>
    <v-col cols="12">
      <quotation-detail></quotation-detail>
    </v-col>
  </v-row>
</template>

<script>
import QuotationDetail from './components/DetailQuotation.vue'

export default {
  components: {
    QuotationDetail,
  },
  setup() {
    return {}
  },
}
</script>
