var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(_vm.isLoading)?_c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-skeleton-loader',{staticClass:"mx-auto pa-1",attrs:{"width":"1200","type":"card"}})],1)]):_c('div',{staticClass:"col-md-12"},[_c('v-card',{staticClass:"elevation-0",attrs:{"color":"transparent"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listInfluencers,"item-key":"id","show-select":_vm.showSelect,"loading":_vm.loadingTable,"loading-text":"Loading... Memuat Data","page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"input":function($event){return _vm.enterSelect()}},scopedSlots:_vm._u([(_vm.showSelect)?{key:"top",fn:function(){return [_c('v-container',{staticClass:"mb-0 pb-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('v-row',{staticClass:"pa-6 pb-0"},[_c('v-select',{staticClass:"mb-0",attrs:{"items":_vm.categoryList,"label":"Kategori"},model:{value:(_vm.categoryValue),callback:function ($$v) {_vm.categoryValue=$$v},expression:"categoryValue"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('v-row',{staticClass:"pa-6 pb-0"},[_c('v-select',{attrs:{"items":_vm.genderList,"label":"Jenis Kelamin"},model:{value:(_vm.genderValue),callback:function ($$v) {_vm.genderValue=$$v},expression:"genderValue"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('v-row',{staticClass:"pa-6 pb-0"},[_c('v-select',{attrs:{"items":_vm.locationList,"label":"Domisili"},model:{value:(_vm.locationValue),callback:function ($$v) {_vm.locationValue=$$v},expression:"locationValue"}})],1)],1)],1)],1)]},proxy:true}:null,{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{},[_c('v-img',{attrs:{"src":item.photo,"alt":item.photo,"height":"80px"}})],1)]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.categories),function(category){return _c('v-chip',{key:category.id,staticClass:"ma-1 caption",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)]}},{key:"item.instagram",fn:function(ref){
var item = ref.item;
return [(item.isPriority === 1)?_c('div',[_vm._v(" "+_vm._s(item.instagram)),_c('br'),_c('v-chip',{staticClass:"primary--text mb-1",attrs:{"color":"","label":"","small":""}},[_vm._v(" Recommended ")])],1):_c('div',[_vm._v(" "+_vm._s(item.instagram)+" ")])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [(item.gender === 'L')?_c('div',[_vm._v(" Laki-laki ")]):_c('div',[_vm._v(" Perempuan ")])]}},{key:"item.engagement_rate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.engagement_rate)+" % ")])]}},{key:"item.city_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.city_name)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-center mt-2 pt-0"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('div',{staticClass:"text-center pb-2"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }